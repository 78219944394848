import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Getting Started with Print</title>
        <meta
          name="description"
          content="How to start your own successful map business."
        />
        <meta name="image" content="https://awmcphee.ca/halftone.jpg" />
        <meta
          property="og:image"
          content="https://awmcphee.ca/halftone.jpg"
        />
        <meta
          name="twitter:card"
          content="https://awmcphee.ca/halftone.jpg"
        />
      </Helmet>
      <h1>Getting Started with Print</h1>
      <h2>Everything I Wish Someone Had Told Me Earlier</h2>
      <p>
        Money is evil (Luke 18:22). But having a successful map business is pretty cool, and I'm grateful.
      </p>
      <p>
        With the following advice, you'll be <u><em>much better-prepared</em></u> to start your own than I was.
      </p>
      <h3>Before You Design</h3>
      <p>Read this first. If you got your start as a digital artist, it's hard to pick a size for your finished product.</p>
      <p>When in doubt, copy the dimensions of a map that already exists. That's what I did (the provincial government maintains a technical 1:750,000 basemap). If you want to be really smart, here's a list of specific considerations:</p>
      <p>Check shipping costs for tubes of different lengths. Is there a cutoff where extra surcharges apply? (With Canada Post, that's 1 meter, or 38 inches.)</p>
      <p>Check what sizes of mailing tube are available. Some sizes are more standard, and therefore cheaper. This is especially relevant if you're considering a square or triangular tube.</p>
      <p>Check what sizes of printing press are available in your area, or your country. (In Canada, they go up to 51" x 73".)</p>
      <p>If you don't read the rest of this, but you trust me to give good advice, <strong>keep your short dimension to 38" or shorter</strong>.</p>
      <h3>Print-on-Demand</h3>
      <p>Here's the first potential business model I'll discuss. Have you ever noticed that printer ink is really expensive?</p>
      <p>
        The sad truth is, it never gets much cheaper in quantity. So although modern inkjet plotters are constantly getting better and cheaper, they use $2-$3 of ink per square foot, and my map is 20 square feet.
      </p>
      <p>
        Since a 42" map also costs about $35 to ship, we're starting to create a very costly product. And that's without pricing out lamination.
      </p>
      <p>
        There are big advantages to print-on-demand. Most importantly, you'll never
        be stuck with excess inventory, which means no warehouse. (Important for some lifestyles.)
      </p>
      <p>
        But my map is huge, my basement spacious, and I always expected it to sell at least a few
        hundred copies. Enter offset.
      </p>
      <h3>Offset Printing</h3>
      <StaticImage className="map" src="../images/halftone.jpg" alt="An extreme closeup showing the characteristic dithered look of an offset print job."/>
      <p>
        If you want quantity, you want to use a real printing press, CMYK, four
        plates. Yes, they still exist!
      </p>
      <p>
        Etching the plates is really expensive, and costs a few thousand
        dollars. After that, you only pay for the paper.
      </p>
      <p>
        Quoting the guy who explained this to me: “the first copy costs
        $5,000, the second copy costs 50 cents”.
      </p>
      <p>Higher startup cost, lower unit cost. Now those are inspirational words to live by.</p>
      <h3>$10,000</h3>
      <p>This is approximately how much cash on hand you should have before considering offset printing.</p>
      <p>My hero, Dave Imus, went <a className="plainlink" href="https://www.hcn.org/issues/45.16" target="_blank" rel="noopener noreferrer">$117,000 into debt</a> to finance his extremely successful <em>Essential Geography of the United States</em>.</p>
      <p>Pre-orders help, crowdfunding helps. I took the shorter route by simply being born into a financially stable family, and then living in a country where a four-year degree can still be paid for with a summer job. (Don't ever let me tell you that the world isn't fundamentally depraved.)</p>
      <h3>Finding a Press</h3>
      <p>
        Most metropolitan areas have a commercial printer with an offset press of some description.
        My only problem was finding a Canadian press big enough for my 68" product (long
        dimension matters). This is one situation where you do <em>not</em> want a charming little company that loves to work with local artists and designers. Find a giant conglomerate.
      </p>
      <p>
        After cold-calling a road map company, I got referred to the <a
          className="plainlink"
          href="https://tigroup.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          TI Group</a> in Toronto. Fellow Canadians: if you need a 73-inch press, this is it.
      </p>
      <h3>Technical Requirements</h3>
      <StaticImage className="map" src="../images/comparison.jpg" alt="Comparison between a laminated and unlaminated copy of the map."/>
      <p>Leave 1/8" clear around the edge of your map. I didn't realize I needed to do this until one week before. Don't be like me.</p>
      <p>You will be submitting a pdf on press day. Mine was 322MB. This is no problem. For best results, leave as many vector features vectorized as possible.</p>
      <p>The CMYK colour process is affordable, but it's a <em>bit</em> different from inkjet printing. Some colours cannot be constructed with any combination of cyan, magenta, yellow, and black, even if your home printer does them fine. This is called the “gamut”.</p>
      <p>I knew in advance that my vivid orange (used for roads and urban footprints) would print a bit darker than it looked on my screen.</p>
      <p>The black plate is manufactured to a sharper level of detail than the other 3 plates, so your black text will have never looked better. Compared to an inkjet print, all of your black features will appear a little bit thinner and crisper.</p>
      <p>Lamination changes your colour balance a little bit. Apparently this is because of the natural properties of the plastic. I find that my unlaminated copies are a little bit colder, while my laminated copies are a little bit warmer.</p>
      <p></p>
      <h3>The Quote</h3>
      <StaticImage className="map" src="../images/shipping.jpg" alt="A man standing next to a truckload of maps."/>
      <p>Be prepared to specify all these things. I wasn't!</p>
      <p><strong>Stock:</strong> paper type. I didn't get too much of a choice here - the printer recommended a 100lb gloss because thinner paper can't survive lamination.</p>
      <p><strong>Finishing:</strong> laminated wall maps are generally more popular, even if they're more expensive. I asked for 2/3rds laminated to 1/3rd unlaminated.</p>
      <p>Will your lamination process add a little bit of length and width? Mine didn't, because the lamination happened before the cutting. Pay attention, mailing tubes are manufactured to exact usable lengths and you won't have room to spare.</p>
      <p><strong>Packaging:</strong> Ugh. This wasn't included in my quote until the very end. Unless you're prepared for all your maps to arrive on a single unmovable pallet, you need to pay extra for cartons (flat boxes).</p>
      <p>I got charged something like $60 a carton, so I cheaped out, and picked 20 cartons of 100 maps each. This would have been fine except <strong>laminated copies are twice as heavy</strong>. A 60-pound box can be moved by 2 out-of-shape guys, a 120-pound box cannot.</p>
      <p>Everything worked out fine in the end, but I had to pay $40 to hire help from the nearby <a className="plainlink" href="https://en.wikipedia.org/wiki/Hutterites" target="_blank" rel="noopener noreferrer">Sand Lake Hutterite Colony</a>.</p>
      <h3>Shipping</h3>
      <StaticImage className="map" src="../images/mailroom.jpg" alt="A dingy vinyl-walled mailroom."/>
      <p>Look up your local shipping costs <em>before</em> you start designing. Since I didn't, I get hit by an ugly $20 oversize surcharge on every tube, and I can't even quote for international orders. (4 inches would make the difference.)</p>
      <p>Private couriers can sometimes be cheaper, but, since I use Canada Post, I get to live anywhere in the country I want. That's pretty cool!</p>
      <p>Setting up your mailroom is where you really want a table which is the size of your map and the height of your arms. Make it yourself if you have to.</p>
      <p>If you need to shift a map across a table, grab it by the middle of its edge. I've accidentally kinked a few copies by trying to leverage them from a corner.</p>
      <p>My dad ran a mail-order home business for many years, so printing my own labels and packing my own tubes has been a source of great comfort for me. I use wheatpaste for all my shipping labels, because it's nearly free and it makes me feel like an anarchist.</p>
      <p>If this all sounds like a nuisance, get yourself a “fulfillment service”. That's the name for a warehouse which you can pay to do all of this for you.</p>
      <h3>Final Thoughts</h3>
      <p>The world needs more good maps. There are so many things left for you to create that are badly needed.</p>
      <p>Contact me for more information.</p>
    </Layout>
  );
}
